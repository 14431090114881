<template>
  <a-card title="我申请的审批" :bodyStyle="{height: '410px'}">
    <a-table
        :columns="columns"
        :data-source="list"
        :loading="tableLoading"
        :pagination="pagination"
        rowKey="id"
        bordered
        @change="pageChange"
    >
      <template slot="applyContent" slot-scope="text">
        <span v-html="text"></span>
      </template>
      <template slot="approveStatus" slot-scope="text">
        <span v-if="text === 0">待审核</span>
        <span v-if="text === 1">同意</span>
        <span v-if="text === 2">拒绝</span>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { getCenterMyProblem, getMyApprove } from '../../api/center/center'
import { mapState } from 'vuex'

export default {
  name: 'myApprove',
  data () {
    return {
      columns: [
        {
          title: '申请时间',
          dataIndex: 'createTime'
        },
        {
          title: '关联任务',
          dataIndex: 'taskName'
        },
        {
          title: '申请审批内容',
          dataIndex: 'applyContent',
          scopedSlots: { customRender: 'applyContent' }
        },
        {
          title: '审批进度',
          dataIndex: 'approveStatus',
          scopedSlots: { customRender: 'approveStatus' }
        }
      ],
      list: [],
      filterForm: {
        pageNum: 1,
        pageSize: 5
      },
      pagination: {
        total: 0,
        pageSize: 5
      },
      tableLoading: true,
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList() {
      this.tableLoading = true
      getMyApprove(this.filterForm).then(res => {
        this.list = res.data.list
        this.pagination.total = res.data.total
        this.tableLoading = false
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
